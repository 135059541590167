<template>
<div>
  <b-card>

    <b-row>
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
                id="icons-search"
                placeholder="Search"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="2">

      </b-col>
      <b-col cols="2">
        <div class="float-right  " style="margin-top: 5px" >
          <b-form-checkbox
              checked="false"
              class="custom-control-primary"
              name="check-button"
              switch
          >
        <span class="">
<!--          <feather-icon icon="CheckIcon" />-->
          <!--        </span>-->
          <!--                <span class="switch-icon-right">-->
          <!--          <feather-icon icon="XIcon" />-->
        </span>
            <div class="font-medium-1">covid shifts</div>
          </b-form-checkbox>
        </div>
      </b-col>
      <b-col cols="2">
        <v-select
        v-model="test"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="[
        {title:'All', value:'hdhdh'},
        {title:'Clinical', value:'hdhdh'},
        {title:'Non clinical', value:'hdhdh'},
        ]"
        class="mb-2"
        label="title"
        multiple
        placeholder="Shift Type"
        />
      </b-col>
      <b-col cols="2">
        <b-form-group>
        <flat-pickr
            v-model="selectedDate"
            :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',}"
            class="form-control"
            placeholder="Date"
        />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-1" cols="12" sm="12" md="6" xs="12" v-for="shift in [1,2,3,4,5,6,7,8,9,10]" >
        <b-card class="shift-card" style="background-color:#daeeee"  >
            <b-row>
              <b-col cols="8">
                <div>
                  <span class="text-bold">Face to Face Consultation</span>
                  <span class="ml-1 text-black-50 "> Home  Visit</span>
                </div>
                <div class="mt-1">
                  <b-badge variant="danger">
                    <span>covid - 19</span>
                  </b-badge>
                  <b-badge variant="success" class="ml-1">
                    <span>Clinical</span>
                  </b-badge>
                </div>
                <div class="mt-1">
                  <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sem consectetur id blandit placerat pellentesque ut. Mattis id sed amet fusce ut amet nisi eu sed.</span>
                </div>
              </b-col>
              <b-col cols="4">
                <b-row class="mt-1" >
                  <feather-icon icon="CalendarIcon" class="mr-1" />
<!--                  {{momentFormat(selectedCell.StartTime,'ddd DD MMMM YYYY')}}-->
                  27/05/2021
                  <!--                        {{moment().format("YYYY Do MM")}}-->
                </b-row>
                <b-row class="mt-1" >
                  <feather-icon icon="ClockIcon" class="mr-1"  />
                  02:30 - 04:30
<!--                  {{momentFormat(selectedCell.StartTime,'HH:mm')}} - {{momentFormat(selectedCell.EndTime,'HH:mm')}}-->
                </b-row>
                <b-row class="mt-1">
                  <feather-icon icon="MapPinIcon" class="mr-1"  />
                  65 Newgate Street, London
                </b-row>
<!--                <b-row class="mt-1" >-->
<!--                  <feather-icon icon="UsersIcon" class="mr-1"  />-->
<!--                  hshshhs-->
<!--&lt;!&ndash;                  {{selectedCell.user.first_name +' ' +selectedCell.user.last_name}}&ndash;&gt;-->
<!--                </b-row>-->
                <b-row class="mt-1">

                  <b-badge style="background-color: white" class="text-black-50">
                    £180.00
                  </b-badge>
                </b-row>
              </b-col>
            </b-row>
          <b-button class="float-right mt-0 apply-button text-primary " variant="primary">Apply Now</b-button>

        </b-card>


      </b-col>
    </b-row>
  </b-card>

</div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {

  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BImg,
  BLink,
  BMedia, BOverlay,
  BRow,
  VBTooltip,
  BListGroupItem,
  BCardTitle,
  BFormGroup,

  VBToggle,
  BModal, VBModal, BAlert,

  BButtonGroup,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BAvatarGroup,
  BFormInput,
  BTable,

  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import MomentMixin from '@/mixins/MomentMixin'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'


export default {
name: "index",
  components: {
    flatPickr,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BListGroupItem,
    BCardTitle,
    BFormGroup,
    VBToggle,
    BModal, VBModal, BAlert,
    BButtonGroup,
    BOverlay, BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  data() {
    return {
      test:'',
      selectedDate:'',

      leaveList:[],
      documentList:[],
      leaveLoading:false,
      buttonLoading:false,
      tableLoading:false,
      noDataTable:'',
      uploadedFile: null,
      showFileUploadModal:false,
      modalLoading:false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'name',
          label: 'DOCUMENT NAME',
          sortable: false
        },
        {
          key: 'date',
          label: 'DATE UPLOADED',
          sortable: true
        },
        // {
        //   key: 'uploaded_by',
        //   label: 'UPLOADED BY',
        //   sortable: false
        // },

        {
          key: 'action',
          label: '',

        },


      ],
      items: []
    }
  },
}
</script>

<style  lang="scss">
@import '@core/scss/vue/libs/vue-select.scss'; /*print style*/
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.shift-card{
  //margin-bottom: 0px;
  //padding-bottom: 10px;
}
.apply-button{
  margin-bottom: -36px;
}



</style>
